import { createRouter, createWebHistory } from 'vue-router'
import dateFormat from "dateformat";

// import HomeView from '@/views/HomeView.vue'
const HomeView = () => import('@/views/HomeView.vue')
// import AdminView from '@/views/admin/AdminView.vue'
const AdminView = () => import('@/views/admin/AdminView.vue')
// import EditView from '@/views/admin/EditView.vue'
const EditView = () => import('@/views/admin/EditView.vue')
// import EditArchive from '@/views/admin/EditArchive.vue'
const EditArchive = () => import('@/views/admin/EditArchive.vue')

// import ScheduleView from '@/views/schedule/ScheduleView.vue'
const ScheduleView = () => import('@/views/schedule/ScheduleView.vue')
// import ScheduleContainer from '@/views/schedule/ScheduleContainer.vue'
const ScheduleContainer = () => import('@/views/schedule/ScheduleContainer.vue')
// import ScheduleDetailContainer from '@/views/schedule/ScheduleDetailContainer.vue'
const ScheduleDetailContainer = () => import('@/views/schedule/ScheduleDetailContainer.vue')

// import TwelveSongsListContainer from '@/views/program/TwelveSongs/TwelveSongsListContainer.vue'
const TwelveSongsListContainer = () => import('@/views/program/TwelveSongs/TwelveSongsListContainer.vue')

// import ProgramDetailContainer from '@/views/program/ProgramDetailContainer.vue'
const ProgramDetailContainer = () => import('@/views/program/ProgramDetailContainer.vue')

// import ProgramArchiveContainer from '@/views/program/ProgramArchiveContainer.vue'
const ProgramArchiveContainer = () => import('@/views/program/ProgramArchiveContainer.vue')

// import ProgramInfoContainer from '@/views/program/ProgramInfoContainer.vue'
const ProgramInfoContainer = () => import('@/views/program/ProgramInfoContainer.vue')

// import ProgramContainer from '@/views/program/ProgramContainer.vue'
const ProgramContainer = () => import('@/views/program/ProgramContainer.vue')

// import WorksContainer from '@/views/works/WorksContainer.vue'
const WorksContainer = () => import('@/views/works/WorksContainer.vue')


// import ListenListening from '@/views/works/ListenListening.vue'
const ListenListening = () => import('@/views/works/ListenListening.vue')

const BlockfloeteUndTrompete = () => import('@/views/works/BlockfloeteUndTrompete.vue')
const AirCap = () => import('@/views/works/AirCap.vue')
const GehWork = () => import('@/views/works/GehWork.vue')
const LoopSong = () => import('@/views/works/LoopSong.vue')
const UebenUebung = () => import('@/views/works/UebenUebung')

// import AboutContainer from '@/views/about/AboutContainer.vue'
const AboutContainer = () => import('@/views/about/AboutContainer.vue')



//get CET date for redirecting to schedule/MONTH
var todayDate = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' }))
const redirectMonth = dateFormat(todayDate, "mmmyyyy")

const routes = [
    {
        path: "/",
        component: HomeView,
        children: [
            {
                path: '/schedule',
                name: 'schedule',
                redirect: '/schedule/' + redirectMonth,
                component: ScheduleView,
                children: [
                    {
                        path: ':id',
                        component: ScheduleContainer,
                        children: [
                            {
                                path: ':idS',
                                component: ScheduleDetailContainer,
                                name: 'test'
                            },
                        ]
                    },
                ]
            },
            {
                path: '/program',
                name: 'program',
                component: ProgramContainer,
                children: [
                    {
                        path: ':id',
                        redirect: to => {
                            return {
                                path: '/program/' + to.params.id + '/info'
                            }

                        },
                        component: ProgramDetailContainer,
                        children: [
    
                            {
                                path: 'info',
                                name: 'program-info',
                                component: ProgramInfoContainer,
                            },

                            {
                                path: 'archive',
                                name: 'program-archive',
                                component: ProgramArchiveContainer,

                            },

                            {
                                path: ':idA',
                                props: true,
                                name: 'songlist',
                                component: TwelveSongsListContainer,
                            },
                        ]
                    },
                ]
            },
            {
                path: '/works',
                name: 'works',
                component: WorksContainer,
                children: [
                    {
                        path: 'listenlistening',
                        component: ListenListening,
                    },
                    {
                        path: 'blockfloeteundtrompete',
                        component: BlockfloeteUndTrompete,
                    },
                    {
                        path: 'aircap',
                        component: AirCap,
                    },
                    {
                        path: 'geh',
                        component: GehWork,
                    },
                    {
                        path: 'loopsong',
                        component: LoopSong,
                    },
                    {
                        path: 'uebenuebung',
                        component: UebenUebung,
                    },
                ]
            },
            { path: '/about', component: AboutContainer },
        ],
    },
    {
        path: '/admin',
        name: 'admin',
        component: AdminView,
    },
    {
        path: '/admin/edit',
        name: 'edit',
        component: EditView
    },
    {
        path: '/admin/archive',
        name: 'edit-archive',
        component: EditArchive
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {

    },
});


export default router;