import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import mitt from 'mitt';
import shuffle from 'shuffle-array'
import './styles/main.css'

// Vuetify
import 'vuetify/styles'
// import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { mdiTriangleSmallDown, mdiTriangleSmallUp, mdiDelete, mdiPencil, mdiArchive, mdiPlusCircle, mdiCloseBoxOutline, mdiCloseOutline, mdiArrowLeft, mdiArrowDown, mdiArrowRight, mdiDownload } from '@mdi/js'

//mitt
const eventBus = mitt();

const vuetify = createVuetify({
    display: {
        mobileBreakpoint: 'md'
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
            triangleDown: mdiTriangleSmallDown,
            triangleUp: mdiTriangleSmallUp,
            delete: mdiDelete,
            pencil: mdiPencil,
            archive: mdiArchive,
            plusCircle: mdiPlusCircle,
            closeBoxOutline: mdiCloseBoxOutline,
            closeOutline: mdiCloseOutline,
            arrowLeft: mdiArrowLeft,
            arrowDown: mdiArrowDown,
            arrowRight: mdiArrowRight,
            download: mdiDownload
        },
        sets: {
            mdi,
        },
    },
})
const app = createApp(App)

//  "https://oolongradio.com:8443"
//  "http://127.0.0.1:8081"

app.use(router)
app.use(vuetify)
app.provide('serverUrl', "https://oolongradio.com:8443")
app.provide('mobile', vuetify.display.mobile)
app.provide('shuffle', shuffle)
app.provide('eventBus', eventBus)
app.mount('#app');

